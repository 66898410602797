<template>
  <WorkLayout :project="project">
    <div class="content container">
      <div class="row">
        <div class="col col-sm-12 p-y-4">
          <img v-lazy="require('@/assets/images/expressvpn/expressvpn.png')" alt="ExpressVPN Project" />
        </div>
      </div>
    </div>
  </WorkLayout>
</template>

<script>
import WorkLayout from './WorkLayout.vue';

export default {
  name: "ExpressVPN",
  components: {
    WorkLayout,
  },
  data() {
    return {
      project: {
        name: "Website & Tools",
        description: "I was part of a team that created hundreds of marketing pages and campaigns, significantly impacting the company's direction and success."
      }
    };
  },
  mounted() {
    document.title = `${this.project.name} - Stan Familleza`;
  },
};
</script>