<template>
  <div class="cv">
    <div class="container">
      <header>
        <div class="row">
          <div class="col col-sm-3 col-md-2">&nbsp;</div>
          <div class="col col-sm-8 col-md-8">
            <h1>
              {{ fname }} {{ lname }}
            </h1>
            <ul>
              <li>{{ email }}</li>
              <li>{{ phone }}</li>
              <li><a href="/">{{ portfolio }}</a></li>
            </ul>  
          </div>  
        </div>
      </header>

      <section class="summary row">
        <div class="col col-sm-3 col-md-2">
          <p class="section-title">Experience:</p>
        </div>
        <div class="col col-sm-8 col-md-8">
          <ul>
            <li v-for="item in summary" :key="item">{{ item }}</li>
          </ul>
        </div>      
      </section>

      <section class="experience">
        <div v-for="(job, index) in jobs" 
          :key="job.title" 
          :id="`job-${index + 1}`" 
          class="row">
          <div class="col col-sm-3 col-md-2">
            <p class="job-date">
              <small>{{ job.dates }}</small>  
            </p>  
          </div>
          <div class="col col-sm-8 col-md-8 experience-item">
            <p class="job-title">
              {{ job.title }}
                
              <small class="job-company">{{ job.company }}</small>
            </p>
            
            <p>{{ job.description }}</p>
            <p>
              <small>Tools: {{ job.tools.join(', ') }}</small>
            </p>
          </div>
        </div>  
      </section>

      <section class="skills">
        <div class="row">
          <div class="col col-sm-3 col-md-2">
            <p class="section-title">Skills & tools:</p> 
          </div>
          <div class="col col-sm-8 col-md-8">
            <p>
              <small>Core design skills</small>
            </p>
            <ul class="two-column-list m-b-2">
              <li v-for="skill in coreDesignSkills" :key="skill">{{ skill }}</li>
            </ul>

            <p>
              <small>Software engineering background</small>
            </p>
            <ul class="two-column-list">
              <li v-for="skill in softwareEngineeringSkills" :key="skill">{{ skill }}</li>
            </ul>
          </div>
        </div>
      </section>

      <section class="education">
        <div class="row">
          <div class="col col-sm-3 col-md-2">
            <p class="section-title">Education:</p>
          </div>
          <div class="col col-sm-8 col-md-8">
            <p>
              {{ education.degree }}
              <small>{{ education.institution }}</small>
            </p>
          </div>
        </div>
      </section>

      <button @click="downloadPDF" class="no-pdf dl-button">Download as PDF</button>

    </div>
  </div>  
</template>

<script>
import { siteConfig } from '@/config';
import html2pdf from 'html2pdf.js';

export default {
  name: "ResumePage",
  data() {
    return {
      fname: siteConfig.fname,
      lname: siteConfig.lname,
      email: siteConfig.email,
      phone: siteConfig.phone,
      portfolio: siteConfig.domain,
      portfolioLink: siteConfig.domain,
      summary: [
        "With over 15 years of experience",
        "Strong visual sense",
        "Broad technical background",
        "Experience with both web and native applications"
      ],
      jobs: [
        {
          dates: "12.2021 - Present",
          title: "Senior UI/UX Designer",
          company: "DTN - Remote",
          description: `Create and manage design systems and guidelines to ensure consistency across products. Support diverse design needs 
          across sectors with user-centered solutions that align with business objectives. Design internal tools to improve functionality 
          and usability for in-house teams.`,
          tools: ["Figma"]
        },
        {
          dates: "05.2021 - 10.2021",
          title: "Senior UI Designer",
          company: "Keyteo Consulting Limited - Hong Kong",
          description: `Led the design and branding of a luxury hotel application, integrating room control and e-commerce functionalities 
          to enhance guest convenience and satisfaction.`,
          tools: ["Figma"]
        },
        {
          dates: "08.2015 - 05.2020",
          title: "UI Designer and UI Engineer",
          company: "ExpressVPN - Hong Kong",
          description: `Designed and developed a high-quality website and brand design system. Conducted A/B testing to refine ideas, 
          improve conversion rates, and enhance user experience across web and app platforms.`,
          tools: ["Sketch, Abstract, HAML/SCSS on Ruby on Rails"]
        },
        {
          dates: "08.2014 - 08.2015",
          title: "UX/UI Designer",
          company: "Contractor - Remote",
          description: `Designed an inventory app for mobile and developed conversion-focused landing pages. Contributed to social 
          media user flows and interfaces to boost user engagement and visual appeal.`,
          tools: ["Tools: Sketch, Photoshop"]
        },
        {
          dates: "05.2012 - 08.2014",
          title: "UX/UI Designer",
          company: "UST Global - Manila",
          description: `Designed app logos, developed skeuomorphic and web app UIs, and created a style guide to ensure design 
          consistency across platforms. Focused on designing for early versions of iPhone, iPod, and iPad, adapting to evolving 
          mobile technologies.`,
          tools: ["Tools: Photoshop, Illustrator"]
        },
        {
          dates: "08.2008 - 05.2012",
          title: "Web Designer and UI Developer",
          company: "Envisia Learning - Remote",
          description: `Worked with a team in California to design and code EnvisiaLearning.com and its web app products. Focused on 
          creating a user-friendly interface and ensuring robust functionality.`,
          tools: ["Tools: Photoshop, HTML/CSS on Ruby on Rails"]
        },
        {
          dates: "03.2007 - 09.2008",
          title: "Web Designer and UI Developer",
          company: "DuPont - Manila",
          description: `Designed and developed a content management system, focusing on user-friendly interface and robust 
          functionality for streamlined content management.`,
          tools: ["Tools: Photoshop, Wordpress"]
        },
      ],
      coreDesignSkills: [
        "User research and analysis", "Wireframing and prototyping", "Interaction Design and IA", 
        "Visual design", "User testing and usability evaluation", "Collaboration and communication", "Design systems and guidelines", 
        "Problem-solving and critical thinking", "Accessibility and inclusive design",
      ],
      softwareEngineeringSkills: [
        "Version Control (Git/GitHub)", "HTML/CSS and JS, Vue.js", "Ruby on Rails", "Agile development"
      ],
      education: {
        degree: "Bachelor of Science in Information Technology",
        institution: "Technological Institute of the Philippines"
      }
    };
  },
  methods: {
    downloadPDF() {
      const element = document.querySelector(".cv");

      // Add a class to target PDF-specific styles
      element.classList.add('pdf-styles');

      const opt = {
        filename: 'stanleyf-resume.pdf',
        pagebreak: { mode: ['css', 'legacy'] },
      };

      // Generate the PDF
      html2pdf()
        .from(element)
        .set(opt)
        .save()
        .then(() => {
          // Remove the class after PDF generation
          element.classList.remove('pdf-styles');
        });
    },
  }  
};
</script>