<template>
    <div class="not-found">
      <h1>Page not found.</h1>
      <p>The page you are looking for does not exist.</p>
      <router-link to="/">Go back to Home</router-link>
    </div>
  </template>

  <script>
  export default {
    name: "NotFound",
  };
  </script>