<template>
  <WorkLayout :project="project">
    <div class="content container">
      <section class="row">
        <div class="col col-sm-12">
          <!-- <img v-lazy="require('@/assets/images/img.svg')" alt="" /> -->
        </div>
      </section>
    </div>
  </WorkLayout>
</template>

<script>
import WorkLayout from './WorkLayout.vue';

export default {
  name: "ConceptsPage",
  components: {
  WorkLayout,
},
data() {
  return {
    project: {
      name: "Concepts",
      description: "This compilation showcases a collection of past work, featuring concepts, ideas, and mockups developed for various clients. It highlights a range of creative solutions tailored to different challenges, offering a glimpse into the design thinking and problem-solving approach applied across multiple projects."
    }
  };
},
mounted() {
  document.title = `${this.project.name} - Stan Familleza`;
},
};
</script>