<template>
  <WorkLayout :project="project">
    <div class="content container">
      <section class="row">
        <div class="col col-sm-12">
          <!-- <img v-lazy="require('@/assets/images/img.svg')" alt="" /> -->
        </div>
      </section>
    </div>
  </WorkLayout>
</template>        
    
<script>
import WorkLayout from './WorkLayout.vue';

export default {
  name: "B2BProducts",
  components: {
  WorkLayout,
},
data() {
  return {
    project: {
      name: "B2B Products",
      description: "Contributed to the design and development of DTN’s Ag, Energy, and Weather products. Focused on enhancing user experience, streamlining workflows, and delivering data-driven solutions to support critical decision-making in these industries."
    }
  };
},
mounted() {
  document.title = `${this.project.name} - Stan Familleza`;
},
};
</script>