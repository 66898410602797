<template>
  <WorkLayout :project="project">
    <div class="content container">
      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/avq/step1.png')" alt="Form groups" class="curvy"/>
        </div>
      </section>
      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/avq/step2.png')" alt="Review the transaction" class="curvy"/>
        </div>
      </section>
      <section class="row">
        <div class="col col-sm-12">
          <img v-lazy="require('@/assets/images/avq/step3.png')" alt="Success information" class="curvy"/>
        </div>
      </section>
    </div>
  </WorkLayout>
</template>

<script>
import WorkLayout from './WorkLayout.vue';

export default {
  name: "BankingApp",
  components: {
  WorkLayout,
},
data() {
  return {
    project: {
      name: "Banking App",
      description: "This project focused on refreshing the user interface for a web banking app, aiming to modernize the design and improve usability. The goal was to create a clean, intuitive interface that makes it easier for users to navigate and manage their banking tasks."
    }
  };
},
mounted() {
  document.title = `${this.project.name} - Stan Familleza`;
},
};
</script>