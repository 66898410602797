<template>
  <WorkLayout :project="project">
    <div class="content container">
      <div class="row">
        <div class="col col-sm-12">
          <p>{ insert content }</p>
          <!-- <img v-lazy="require('@/assets/images/expressvpn/expressvpn.png')" alt="ExpressVPN Project" />
          <router-link to="/" class="back-link">Back to Home</router-link> -->
        </div>
      </div>
    </div>
  </WorkLayout>
</template>

  <script>
  import WorkLayout from './WorkLayout.vue';

  export default {
    name: "ArchivePage",
    components: {
      WorkLayout,
    },
    data() {
      return {
        project: {
          name: "Archive",
          description: "A compilation of past work, just for fun. It’s interesting to see how design taste and standards have evolved over time."
        }
      };
    },
    mounted() {
      document.title = `${this.project.name} - Stan Familleza`;
    },
  };
  </script>