import { inject } from '@vercel/analytics';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles/main.scss';
import VueLazyload from 'vue-lazyload';

const app = createApp(App);

inject();

// Register the vue-lazyload plugin globally
app.use(VueLazyload, {
    preLoad: 1.3,
    error: 'path/to/error.png', // Optional: Add a placeholder image for errors
    loading: require('@/assets/images/loading.svg'), // Use the SVG file as the loading placeholder
    attempt: 1
  });

app.use(router);

app.mount('#app');