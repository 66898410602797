<template>
  <WorkLayout :project="project">
    <div class="content container full">
      <section class="row">
        <div class="col col-sm-12 phones">
          <img v-lazy="require('@/assets/images/yoho/yoho1.png')" alt="Yoho 1" />
          <img v-lazy="require('@/assets/images/yoho/yoho2.png')" alt="Yoho 2" />
          <img v-lazy="require('@/assets/images/yoho/yoho3.png')" alt="Yoho 3" />
          <img v-lazy="require('@/assets/images/yoho/yoho4.png')" alt="Yoho 4" />
          <img v-lazy="require('@/assets/images/yoho/yoho5.png')" alt="Yoho 5" />
          <img v-lazy="require('@/assets/images/yoho/yoho6.png')" alt="Yoho 6" />
          <img v-lazy="require('@/assets/images/yoho/yoho7.png')" alt="Yoho 7" />
          <img v-lazy="require('@/assets/images/yoho/yoho8.png')" alt="Yoho 8" />
        </div>
      </section>
    </div>
  </WorkLayout>
</template>

<script>
import WorkLayout from './WorkLayout.vue';

export default {
  name: "MallNavigator",
  components: {
    WorkLayout,
  },
  data() {
    return {
      project: {
        name: "Mall Navigator",
        description: "Make mall navigation effortless with a customized map featuring exclusive store promotions, accessible PWD paths, quick access to customer service, and more."
      }
    };
  },
  mounted() {
    document.title = `${this.project.name} - Stan Familleza`;
  },
};
</script>