<template>
  <WorkLayout :project="project">
    <div class="content container">
      <section class="row">
        <div class="col col-sm-12">
          <!-- <img v-lazy="require('@/assets/images/img.svg')" alt="" /> -->
        </div>
      </section>
    </div>
  </WorkLayout>
</template>

<script>
import WorkLayout from './WorkLayout.vue';

export default {
  name: "DesignSystem",
  components: {
  WorkLayout,
},
data() {
  return {
    project: {
      name: "Design System",
      description: "Led the creation and development of a comprehensive design system, including components, recipes, and principles, to support DTN’s diverse products across multiple sectors. This system ensured consistency, efficiency, and scalability in design, benefiting numerous projects and teams."
    }
  };
},
mounted() {
  document.title = `${this.project.name} - Stan Familleza`;
},
};
</script>